import React, { useCallback, useState } from 'react';

type CertificateSheetProps = {
  partner?: string | null
}

const PARTNERS: any = {
  'empty': {},
  'fitstars': {
    name: 'Fitstars',
    color: '#FD2BE6',
    present: "images/partners/fitstars-present.png",
    logo: "images/partners/fitstars.png",
    title: '№1 фитнес-платформа для домашних тренировок в России',
    gift: 'Месяц тренировок без оплаты',
    code: 'PROSTOAPP',
    instructionsLink: 'http://wow.prostoapp.ru/fitstars',
    info: "images/partners/fitstars-info.png",
  },
  'yasno': {
    name: 'Ясно',
    color: '#42B2FC',
    present: "images/partners/yasno-present.png",
    logo: "images/partners/yasno.png",
    title: 'сервис подбора и консультаций с психологом онлайн',
    gift: 'Скидка 20% на первые 3 консультации',
    code: 'YASNO',
    instructionsLink: 'http://wow.prostoapp.ru/yasno',
    info: "images/partners/yasno-info.png",
  },
  'synchro': {
    name: 'Синхронизация',
    color: '#A67CB6',
    present: "images/partners/synchro-present.png",
    logo: "images/partners/synchro.png",
    title: `это онлайн-лекторий с курсами, которые вдохновляют. 
Вырвитесь из рутины и найдите новые 
темы для общения с близкими, подарив
себе интересный и развивающий досуг 
на полгода вперед`,
    gift: 'Скидка 30% на 6 месяцев подписки',
    code: 'ПРОСТО',
    instructionsLink: 'http://wow.prostoapp.ru/synchronize',
    info: "images/partners/synchro-info.png",
  },
  'sekta': {
    name: '#Sekta',
    color: '#3ED8C4',
    present: "images/partners/sekta-present.png",
    logo: "images/partners/sekta.png",
    title: `онлайн-школа фитнеса с научным подходом `,
    gift: 'Скидка 10% при оплате любого комплексного курса',
    code: 'PROSTO',
    instructionsLink: 'http://wow.prostoapp.ru/sektaschool',
    info: "images/partners/sekta-info.png",
  },
  'studio': {
    name: 'Prosto Studio',
    color: '#5C84B2',
    present: "images/partners/studio-present.png",
    logo: "images/partners/studio.png",
    title: `телесные практики для управления эмоциональным состоянием.`,
    gift: 'Доступ к 3 занятиям без оплаты',
    code: 'PROSTO',
    instructionsLink: 'https://t.me/+UWSImQtTFVs2ZDNi',
    info: "images/partners/studio-info.png",
  }
}

const PartnerCertificate = ({ partner }: CertificateSheetProps) => {

  const params = PARTNERS[partner || 'empty']

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const copyCodeToClipboard = useCallback(() => {
    if (copiedToClipboard) {
      return setCopiedToClipboard(false)
    }
    navigator.clipboard.writeText(params.code || '')
    setCopiedToClipboard(true)
  }, [params, copiedToClipboard])

  return (
    params?.name ?
      <>
        <div className='hideOnMobile' style={{
          paddingTop: 64,
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Gotham Pro Regular',
          fontSize: '16px',
          lineHeight: '20px',
        }}>
          <div>
            <img src={params.present} width="100" height="72" />
          </div>

          <p style={{
            fontFamily: 'Gilroy Semibold',
            fontSize: 32,
            marginTop: 12,
          }}>
            Подарок от партнёра
          </p>
          <div style={{
            width: 886,
            alignSelf: 'center',
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'center',
            background: '#F5F1EF',
            borderRadius: 16,
            padding: 32,
            overflow: "hidden"
          }}>
            <div style={{ marginRight: 16 }}>
              <img src={params.logo} width="auto" height="60" />
            </div>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignContent: 'flex-start',
              paddingRight: 80
            }}>
              <p style={{ textAlign: 'left', marginTop: 6, }}>
                <b style={{ color: params.color }}>{params.name}</b> - {params.title}
              </p>
              <p style={{
                marginTop: 6,
                textAlign: 'left',
                fontFamily: 'Gilroy Semibold',
                fontSize: 26,
                lineHeight: '28px',
                color: params.color,
              }}>
                {params.gift}
              </p>
              {partner === 'studio' ? <>
                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                  <a href={params.instructionsLink}>
                    <img src={'images/partners/studio-link.png'} width="274" height="48" />
                  </a>
                  <div style={{ display: 'flex', gap: 15, alignItems: 'center', marginTop: 15 }}>
                    Состояние
                    <img src={'images/partners/studio-arrow.png'} width="23" height="15" />
                    Действие
                    <img src={'images/partners/studio-arrow.png'} width="23" height="15" />
                    Результат
                  </div>
                </div>
              </> : <>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Gilroy Semibold',
                  fontSize: 20,
                }}>
                  Промокод
                  <div style={{
                    marginLeft: 16,
                    width: 236,
                    height: 38,
                    background: copiedToClipboard ? "#000" : "#fff",
                    borderRadius: 8,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                    onClick={copyCodeToClipboard}
                  >
                    {copiedToClipboard ?
                      <p style={{
                        margin: '0 auto',
                        padding: 0,
                        color: "#fff",
                        fontFamily: 'Gilroy Semibold',
                        fontSize: 18,
                      }}
                      >
                        {'Скопировано'}
                      </p> :
                      <>
                        <p style={{
                          margin: '0 auto',
                          padding: 0,
                          fontFamily: 'Gilroy Bold',
                          fontSize: 22,
                        }}
                        >
                          {params.code}
                        </p>
                        <img
                          src="images/pink/copy.png"
                          width="24"
                          height="24"
                          style={{ marginRight: 16 }}
                        />
                      </>
                    }
                  </div>
                </div>
                <p style={{
                  marginTop: 32,
                  textAlign: 'left',
                }}>Инструкция по промокоду —
                  <a style={{
                    color: params.color,
                    marginLeft: 5
                  }}
                    href={params.instructionsLink}>по ссылке.</a>
                </p>
              </>
              }

            </div>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              width: 355,
            }}>
              <img src={params.info} width="345" height="auto" />
            </div>
          </div>
        </div >
        <div className='hideOnDesktop' style={{
          paddingTop: 64,
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Gotham Pro Regular',
          fontSize: '16px',
          lineHeight: '20px',
        }}>

          <div style={{
            width: 343,
            alignSelf: 'center',
            background: '#F5F1EF',
            borderRadius: 16,
            overflow: "hidden"
          }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              padding: 16,
              paddingTop: 32
            }}>
              <img src={params.present} width="100" height="72" />
              <p style={{
                fontFamily: 'Gilroy Semibold',
                fontSize: 24,
                lineHeight: '32px',
                marginTop: 12,
              }}>
                Подарок <br />
                от партнёра
              </p>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 16 }}>
                  <img src={params.logo} width="auto" height="60" />
                </div>
                <p style={{ textAlign: 'left', fontSize: 14, marginTop: 0, }}>
                  <b style={{ color: params.color }}>{params.name}</b> - {params.title}
                </p>
              </div>
              <p style={{
                marginTop: 6,
                textAlign: 'center',
                fontFamily: 'Gilroy Semibold',
                fontSize: 22,
                lineHeight: '28px',
                color: params.color,
              }}>
                {params.gift}
              </p>
              {partner === 'studio' ? <>
                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                  <a href={params.instructionsLink}>
                    <img src={'images/partners/studio-link.png'} width="274" height="48" />
                  </a>
                  <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginTop: 15, marginBottom: 25, fontSize: 14, }}>
                    Состояние
                    <img src={'images/partners/studio-arrow.png'} width="23" height="15" />
                    Действие
                    <img src={'images/partners/studio-arrow.png'} width="23" height="15" />
                    Результат
                  </div>
                </div>
              </> : <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <p style={{
                    padding: 0,
                    margin: 0,
                    fontFamily: 'Gilroy Semibold',
                    fontSize: 18,
                    marginBottom: 15
                  }}>Промокод</p>

                  <div style={{
                    marginLeft: 16,
                    width: 236,
                    height: 38,
                    background: copiedToClipboard ? "#000" : "#fff",
                    borderRadius: 8,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                    onClick={copyCodeToClipboard}
                  >
                    {copiedToClipboard ?
                      <p style={{
                        margin: '0 auto',
                        padding: 0,
                        color: "#fff",
                        fontFamily: 'Gilroy Semibold',
                        fontSize: 18,
                      }}
                      >
                        {'Скопировано'}
                      </p> :
                      <>
                        <p style={{
                          margin: '0 auto',
                          padding: 0,
                          fontFamily: 'Gilroy Bold',
                          fontSize: 22,
                        }}
                        >
                          {params.code}
                        </p>
                        <img
                          src="images/pink/copy.png"
                          width="24"
                          height="24"
                          style={{ marginRight: 16 }}
                        />
                      </>
                    }
                  </div>
                </div>
                <p style={{
                  marginTop: 32,
                  textAlign: 'center',
                  fontSize: 14,
                  marginBottom: 30,
                }}>Инструкция по промокоду — <br />
                  <a style={{
                    color: params.color,
                    marginLeft: 5
                  }}
                    href={params.instructionsLink}>по ссылке.</a>
                </p>
              </>
              }
              <div style={{
                display: "flex",
                alignSelf: 'center',
                flexDirection: 'column',
              }}>
                <img src={params.info} width="301" height="auto" />
              </div>

            </div>
          </div>
        </div >
      </>
      : <></>
  );
}

export default PartnerCertificate;
