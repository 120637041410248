import React, { useCallback, useState } from 'react';
import Lottie from 'react-lottie';
import CertificateSheet from './CertificateSheet';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: require('../../assets/animations/1.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
};

const introAnimation = require('../../assets/animations/1.json')
const introAnimationMobile = require('../../assets/animations/1-mobile.json')

const openAnimation = require('../../assets/animations/2.json')
const openAnimationMobile = require('../../assets/animations/2-mobile.json')
const openAnimationYear = require('../../assets/animations/2-year.json')
const openAnimationYearMobile = require('../../assets/animations/2-year-mobile.json')

const CertificateOpen: React.FC = () => {
  const isMobile = window.screen.availWidth < 801
  // console.log('isMobile', isMobile)
  const params = new URLSearchParams(window.location.search)
  const code = params.get("code")
  const id = params.get("id")
  const isForever = params.get("isForever")
  const partner = params.get("partner")
  const [introPlayed, setIntroPlayed] = useState(false)
  const [showOpenAnimation, setShowOpenAnimation] = useState(false)
  const [showCertificateSheet, setShowCertificateSheet] = useState(false)

  const openCertificate = useCallback(() => {
    if (!introPlayed) return
    setShowOpenAnimation(true)
  }, [introPlayed])

  return (
    <div style={{
      position: 'absolute',
      background: '#fff',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      {!showOpenAnimation ?
        <div style={{
          zIndex: 999,
          cursor: 'pointer',
          width: '100%',
          height: '100%',
        }}
          onClick={openCertificate}
        >
          <Lottie
            isClickToPauseDisabled
            options={{
              ...animationOptions,
              animationData: isMobile ? introAnimationMobile : introAnimation
            }}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => setIntroPlayed(true),
              },
            ]}
          />
        </div> :
        <div
          style={{
            width: '100%',
            height: '100%',
            zIndex: 999,
          }}
        >
          <Lottie
            isClickToPauseDisabled
            options={{
              ...animationOptions,
              // animationData: openAnimation
              animationData: isMobile ?
                (isForever === 'true' ? openAnimationMobile : openAnimationYearMobile) :
                (isForever === 'true' ? openAnimation : openAnimationYear)
              // animationData: openAnimationYear
            }}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => setShowCertificateSheet(true),
              },
            ]}
          />
        </div >
      }
      {showCertificateSheet &&
        <CertificateSheet
          visible
          code={code}
          id={id}
          isForever={isForever}
          partner={partner}
        />}
    </div>
  );
}

export default CertificateOpen;
