import BuyGiftSubscriptionApi from "./api";
import config from "../config/config";
import "./TinkoffFrameFix.css";

interface IPromoCode {
  id: number;
  text: string;
  price: number;
  amountTotal: number;
  paymentLink?: string;
}

export type TPaymentMethod = 'tinkoff' | 'stripe'

class BuyGiftSubscriptionController {
  private api = new BuyGiftSubscriptionApi();

  async buyPromoCode(email: string, months: number, price: number, discount: number, paymentMethod: TPaymentMethod, isForever?: boolean): Promise<IPromoCode | false> {
    const promoCode = await this.createPromoCode(email, months, price, discount, paymentMethod, isForever);
    if (!promoCode) {
      return false
    }
    // if (!await this.requestUserPayment(email, promoCode, months)) {
    //   return false;
    // }

    return promoCode;
  }

  private async createPromoCode(email: string, months: number, price: number, discount: number, paymentMethod?: TPaymentMethod, isForever?: boolean): Promise<IPromoCode | null> {
    const response = await this.api.createPromoCode({
      isCorporate: false,
      method: "card",
      amountTotal: 1,
      email,
      months,
      price,
      discount,
      paymentMethod,
      isForever
    })

    if (!response) {
      return null;
    }

    return {
      id: response.id,
      text: response.text,
      price: response.price,
      amountTotal: response.amountTotal,
      paymentLink: response.link,
    }
  }

  private async requestUserPayment(email: string, promoCode: IPromoCode, months: number): Promise<boolean> {
    const form = this.createTinkoffForm({
      totalPrice: promoCode.amountTotal * promoCode.price,
      orderId: promoCode.id,
      description: `Подписка на ${months} месяцев для ${email}`,
      email
    })
    window.pay(form);
    return true;
  }

  private createTinkoffForm(params: {
    totalPrice: number,
    orderId: number,
    description: string,
    email: string,
  }) {
    const receiptData =
    {
      Email: params.email,
      EmailCompany: "help@prostoapp.com",
      Taxation: "usn_income",
      Items: [{
        Name: "Cертификат на подписку на приложение Prosto",
        Price: params.totalPrice * 100,
        Quantity: 1.00,
        Amount: params.totalPrice * 100,
        Tax: "none",
      }],
    }

    const form = document.createElement("form");

    const fields = document.createDocumentFragment();
    fields.appendChild(this.createInput("terminalkey", config.tinkoffTerminalKey));
    fields.appendChild(this.createInput("frame", "true"));
    fields.appendChild(this.createInput("language", "ru"));
    fields.appendChild(this.createInput("amount", params.totalPrice.toString()));
    fields.appendChild(this.createInput("order", params.orderId.toString()));
    fields.appendChild(this.createInput("description", params.description));
    fields.appendChild(this.createInput("email", params.email));
    fields.appendChild(this.createInput("receipt", JSON.stringify(receiptData)));


    form.appendChild(fields);

    return form;
  }

  private createInput(name: string, value: string) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  }
}

export default BuyGiftSubscriptionController
