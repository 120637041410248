import React from 'react';
import './CertificateBlock.css';

const sleep = "images/black-friday/sleep.png";
const meditation = "images/black-friday/meditation.png";
const irena = "images/black-friday/irena.png";

const podarokItems = [
  { image: "images/CertificateDownload.png", text: 'Скачайте доступ из письма' },
  { image: "images/Letter.png", text: 'Распечатайте доступ и подарите, или перешлите его тому, кому делаете подарок' },
  { image: "images/Certificate.png", text: 'Получатель доступа сможет легко активировать промокод. Инструкция есть во вложении письма' },
  { image: "images/screenshot.png", text: 'Выложи скриншот о покупке в stories, отметь @prosto.meditation и участвуй в розыгрыше ценных призов' }
]

const isForeverItems = [
  { image: "images/forever/download.png", text: 'Скачайте доступ из письма' },
  { image: "images/forever/letter.png", text: 'Распечатайте доступ и подарите, или перешлите его тому, кому делаете подарок' },
  { image: "images/forever/certificate.png", text: 'Получатель сможет легко активировать промокод. Инструкция есть во вложении письма' },
  { image: "images/forever/screenshot.png", text: 'Выложи скриншот о покупке в stories, отметь @prosto.meditation и участвуй в розыгрыше ценных призов' }
]

const pinkItems = [
  { image: "images/pink/download.png", text: 'Скачайте доступ из письма' },
  { image: "images/pink/letter.png", text: 'Распечатайте доступ и подарите, или перешлите его тому, кому делаете подарок' },
  { image: "images/pink/ticket.png", text: 'Получатель сертификата сможет легко активировать промокод. Инструкция есть в сертификате' },
  { image: "images/pink/screenshot.png", text: 'Выложи скриншот о покупке в stories, отметь @prosto.meditation и участвуй в розыгрыше ценных призов' }
]

const blackFridayItems = [
  { image: sleep, text: '«Prosto: медитация и сон» станет вашей внутренней опорой и поможет выработать привычку медитировать каждый день.' },
  { image: meditation, text: 'Практики ментальной устойчивости помогут побороть стресс, тревогу, злость, срывы в питании и проблемы со сном.' },
  { image: irena, text: 'Prosto.Конференция поможет выстроить гармоничные отношения с окружающим миром.' }
]

interface IProps {
  isBlackFriday?: boolean
  isPink?: boolean
  isHoliday?: boolean
  isForever?: boolean
}

const CertificateInstructionsBlock: React.FC<IProps> = ({
  isBlackFriday = false, isPink, isForever
}) => {
  const items = isBlackFriday ? blackFridayItems :
    isPink ? pinkItems :
      isForever ? isForeverItems :
        podarokItems

  const color = isForever || isBlackFriday ? '#fff' : '#000'

  return (
    <div className="CertificateMainBlock">
      {items.map((item, key) => (
        <div key={key} className="CertificateContainer">
          <img className="CertificateImage" src={item.image} height={isBlackFriday ? 120 : 50} />
          <p className="CertificateText" style={{ color }}>{item.text}</p>
        </div>
      ))}
      {/* {isHoliday &&
        <div className="CertificateContainer">
          <img className="CertificateImage" src={screenshot} style={{marginTop: -15}} />
          <p className="CertificateText" style={{ color }}>
            Выложи скриншот<br />
            о покупке в stories,<br />
            отметь <b>@prosto.meditation</b> <br />
            и участвуй в розыгрыше <br />
            ценных призов</p>
        </div>
      } */}
    </div>
  );
}

export default CertificateInstructionsBlock;
