import React from 'react';
import './CustomCheckbox.css';

export interface ICustomCheckboxProps {
  title: string;
  value: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({ title, value, onChange, disabled }) => {
  return (
    <div className="checkbox">
      <input
        title='confirm-checkbox'
        className="custom-checkbox"
        id="confirm-checkbox"
        type="checkbox"
        checked={value}
        onChange={() => { }}
      />
      <label
        htmlFor="confirm-checkbox"
        style={{
          fontFamily: 'Gotham Pro Regular',
          fontSize: 16
        }}
        onClick={() => !disabled && onChange(!value)}>{title}</label>
    </div>
  );
}

export default CustomCheckbox;
