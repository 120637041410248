import React, { } from 'react';
import CertificateInstructionsBlock from '../../components/CertificateBlock/CertificateBlock';
import CustomButton from '../../components/CustomButton/CustomButton';
import Footer from '../../components/Footer/Footer';
import { useHolidaysTheme } from '../../themes/useTheme';
import './CertificateScreen.css';
import { YMInitializer } from 'react-yandex-metrika';
import { ymOptions } from '../../navigation/DomainRoutes';

const voices = "images/voices.png";
const voicesMobile = "images/voices-mobile.png";

const CertificateScreen: React.FC = () => {
  // domainRouter.currentHost
  // console.log('domainRouter.currentHost', domainRouter.currentHost)
  const isForever = window.location.search.substring(1) === 'isForever'
  const isHoliday = true
  const { isBirthday, isBlackFriday, isPink, styles } = useHolidaysTheme()
  const yandexMetrikaCounters = isForever ? [98501558] : [98498599]
  // console.log('yandexMetrikaCounters', yandexMetrikaCounters)

  return (
    <>
      <YMInitializer accounts={yandexMetrikaCounters} options={ymOptions} version="2" />
      <div className={isForever ? "ForeverCertificateBlock" : "CertificateBlock"}>
        {/* {isBirthday && <img className="AppIcon" src={birthdayIcon} />}
      {isPink && <img className={styles.AppIcon || "AppIcon"} src={"images/pink/pink-logo.png"} />} */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
          {isForever ? <>
            <img width={270} height={92} src={"images/forever/certificate-icon.png"} />
            <img width={114} height={69} src={"images/letter-sent-white.png"} />
          </> : <>
            <img width={76} height={76} src={"images/app-icon.png"} />
            <img width={114} height={69} src={"images/letter-sent.png"} />
          </>}
        </div>

        {/* {isPink && <img className={styles.CertificatesTitleImage || "AppIcon"} src={"images/pink/sent-letter.png"} />} */}

        <div className="hideOnMobile">
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <p className="TitleText" style={{ color: isForever ? '#fff' : '#000', marginTop: 10 }}>
              Поздравляем с покупкой доступа
              <br />
              {isForever ?
                <>
                  Prosto Навсегда <span style={{ color: '#FFB700' }}>со скидкой 67%</span> 🎉
                </> : <>
                  Prosto на год <span style={{ color: '#33B49D' }}>со скидкой 67%</span> 🎉
                </>
              }
              <br />
              Проверьте вашу почту!
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img width={550} src={voices} />
              <p className="CertificateText"
                style={{ color: isForever ? '#fff' : '#000', width: 260, textAlign: 'left' }}
              >
                С нами более <b>3 миллионов пользователей</b> — присоединяйся!
                <br /><br />
                Российские звёзды медитируют сами и их голоса ведут тебя в медитациях:
                <br /><br />
                Юра Борисов<br />
                Никита Ефремов<br />
                Максим Матвеев<br />
                Равшана Куркова<br />
                Дарья Мельникова<br />
                Марина Александрова<br />
                Ирена Понарошку<br />
                Сергей Чонишвили<br />
                Н. Н. Дроздов<br />
              </p>
            </div>
          </div>
        </div>

        <div className="hideOnDesktop">

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <p className="TitleText" style={{ color: isForever ? '#fff' : '#000', marginTop: 10 }}>
              Поздравляем с покупкой <br />
              доступа Prosto 
              <br />
              {isForever ?
                <>
                  Навсегда <span style={{ color: '#FFB700' }}>со скидкой 67%</span> 🎉
                </> : <>
                  на год <span style={{ color: '#33B49D' }}>со скидкой 67%</span> 🎉
                </>
              }
              <br />
              Проверьте вашу почту!
            </p>
            <p className="CertificateText" style={{ color: isForever ? '#fff' : '#000' }}>
              С нами более <b>3 000 000</b> человек — <br />
              присоединяйся!
              <br /><br />
              Российские звёзды медитируют сами <br />
              и их голоса ведут тебя в медитациях:
              <br />
            </p>
            <br />
            <p className="CertificateText"
              style={{ color: isForever ? '#fff' : '#000', marginBottom: -200, textAlign: 'left', marginLeft: '50%' }}
            >
              Юра Борисов<br />
              Никита Ефремов<br />
              Максим Матвеев<br />
              Равшана Куркова<br />
              Дарья Мельникова<br />
              Марина Александрова<br />
              Ирена Понарошку<br />
              Сергей Чонишвили<br />
              Н. Н. Дроздов<br />
            </p>
            <img src={voicesMobile} width={'100%'} />
          </div>
        </div>

        <CertificateInstructionsBlock
          isHoliday={isHoliday}
          isBlackFriday={isBlackFriday}
          isPink={isPink}
          isForever={isForever}
        />

        {
          isHoliday && (
            <>
              <p className={styles.TitleTextBottom || "TitleTextBottom"}
                style={{ color: isForever ? '#fff' : '#000', }}
              >
                Участвуй в розыгрыше подарков
                общей стоимостью более <br className='hideOnDesktop' />1 000 000₽
                в наших соцсетях! <br />
                Жми, чтобы увидеть условия 👇
              </p>

              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                <a className="Link" href="https://www.instagram.com/prosto.meditation" target='_blanc'>
                  <CustomButton
                    className={'CustomButton'}
                    style={{
                      backgroundColor: isForever ? '#FFB700' : '#33B49D',
                      color: isForever ? '#000' : '#fff'
                    }}
                    title={'Участвовать'}
                    icon={isForever ? 'images/instagram-black.png' : 'images/instagram-white.png'}
                  />
                </a>
              </div>
            </>
          )
        }

        <Footer
          showSellerInfo={false}
          backgroundColor={'transparent'}
          textColor={isForever ? '#fff' : '#000'}
          linkColor={isForever ? '#fff' : '#33B49D'}
        />
      </div >
    </>
  );
}

export default CertificateScreen;
